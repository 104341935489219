html {
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Inter", Arial, sans-serif;
  /* font-weight: bolder; */
  background: white;
}

p {
  font-size: 18px;
}

li {
  font-size: 18px;
}

body {
  min-height: 99vh;
  margin: 0;
}

#root {
  min-height: 98vh;
}

#root > div {
  display: flex;
  flex-direction: column;
  min-height: 98vh;
}

.content {
  flex-grow: 1;
}

nav {
  min-width: 900px;
  display: block;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
}

header {
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
  /* height: 80px; */
  height: 8vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5vw;
  max-width: 100%;
  background: rgba(93, 145, 205, 1);
  z-index: 2;
}

.largeDisplay {
  display: flex;
  flex-direction: row;
}

.largeDisplay li {
  padding-left: 0px;
  height: 50px;
  width: 200px;
  text-align: center;
}

.smallDisplay {
  display: flex;
  flex-direction: column;
}

.smallDisplay li {
  padding-left: 0px;
  text-align: center;
}

iframe {
  margin: auto;
}

.logo img {
  /* height: 80px; */
  height: 7vh;
  /* margin-top: -20px; */
  border: 2px solid white;
  padding-top: 2px;
}

nav ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  margin: 0;
  justify-content: space-around;
}

nav ul li {
  /* height: 80px; */
  height: 7vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav ul li a {
  color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

nav ul li a:hover {
  color: #243952;
}

.navActive a {
  font-weight: bolder;
}

header a {
  text-decoration: none;
}

.showMenu {
  display: none;
}

.showNav {
  display: block;
  z-index: 1;
}

#contactform {
  display: flex;
  flex-direction: column;
  width: 525px;
}

#contactform button {
  align-self: center;
  width: 150px;
  height: 50px;
  background: #243952;
  color: white;
  box-shadow: 0;
  font-size: 16px;
  font-weight: bolder;
  border: none;
  font-family: "Titillium Web", sans-serif;
  cursor: pointer;
  border-radius: 4px;
}

#contactform button:hover {
  background: #121c29;
}

.contactitem {
  margin-left: 15px;
}

input {
  width: 100%;
  padding: 7px 14px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  width: 100%;
  padding: 7px 14px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
}

.contentcontact {
  width: 80%;
  margin: 2vw auto;
}

.contactpage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 70px;
}

.contactcardlogo {
  margin-top: 20px;
  margin-left: 50px;
}

.specialinput {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.profilephoto {
  max-width: 18vw;
}

.curriculum {
  background: white;
  color: black;
  padding: 50px;
  width: 72vw;
  text-align: justify;
  text-justify: inter-word;
}

.workareasimage {
  background: white;
  color: black;
  width: 72vw;
}

.curriculum h2 {
  font-family: "Playfair Display SC", serif;
}

.curriculumname {
  text-align: center;
}

.curriculum ul li {
  margin-bottom: 15px;
}

.landingpagetop,
.landingpagetopworkareas,
.landingpagetopcurriculum {
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  background: rgba(93, 145, 205, 1);
  color: white;
}

.workareatopdiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: rgba(93, 145, 205, 1);
  color: white;
  justify-content: space-between;
}

.landingpagephoto {
  width: 65vw;
  object-fit: cover;
}

.workareaphoto {
  max-width: 50vw;
  object-fit: contain;
}

.teachingphoto {
  width: 50vw;
  object-fit: cover;
  object-position: right top;
}

.teachingjustify {
  text-align: justify;
  text-justify: inter-word;
}

.homeimage {
  width: 100vw;
  max-width: 100%;
  max-height: 82vh;
  object-fit: cover;
  object-position: right top;
}

footer {
  width: 100vw;
  max-width: 100%;
  text-align: center;
}

.homepageoverlayfooter {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
}

.secondarysection {
  margin-left: 10vw;
  margin-right: 10vw;
}

.secondarysection blockquote,
.secondarysection p,
.secondarysection ol,
.teachingcontent blockquote p,
.teachingcontent ol,
.teachingcontent blockquote {
  margin-top: 5px;
  margin-bottom: 5px;
}

.secondarysection ol {
  font-size: 18px;
}

ol li {
  padding-bottom: 5px;
  text-align: justify;
  text-justify: inter-word;
}

.course blockquote p {
  font-size: 16px;
}

.catalogue {
  margin-top: 40px;
  width: 80%;
}

.teachingmainsection {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 85%;
  margin: auto;
}

.bonecos {
  width: 15%;
}

.bonecos img {
  width: 100%;
  /* max-width: 13vw; */
  margin-top: 40px;
}

#catalogo {
  margin-bottom: 30px;
}

.overlay {
  position: absolute;
  top: 10vh;
  right: 0;
  /* background: white; */
  background: rgba(93, 145, 205, 1);
  opacity: 70%;
  width: 48vw;
  min-width: calc(640px + 8vw);
  height: 0;
  transition: height 1s ease-in-out;
  z-index: 0;
}

.overlaytext {
  position: absolute;
  top: 7vh;
  right: 0;
  width: 40vw;
  min-width: calc(640px);
  margin: 4vw 4vw 0 0;
  display: none;
  color: white;
  /* color: black; */
  z-index: 1;
  flex-direction: column;
  height: 77vh;
  justify-content: space-between;
  align-content: space-between;
}

.overlaytext h1 {
  font-size: 30px;
  /* text-align: center; */
  font-family: "Playfair Display SC", serif;
}

.overlaytext h2 {
  /* text-align: center; */
  font-size: 25px;
  font-family: "Playfair Display SC", serif;
}

.overlaytext h3 {
  font-size: 17px;
}

.homepagelist {
  list-style: none;
  padding-left: 5px;
}

.homepagemissiondiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  min-width: 640px;
}

.homepagemission {
  width: 175px;
  height: 175px;
  color: rgba(93, 145, 205, 1);
  background: white;
  text-align: center;
  font-size: 19px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
}

.homecontact {
  width: 150px;
  height: 50px;
  background: #243952;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 4px;
  cursor: pointer;
}

.homecontact:hover {
  background: #121c29;
}

.homecontact a {
  text-decoration: none;
  color: white;
  font-weight: bolder;
  font-size: 18px;
}

.homecontact a:visited {
  color: white;
}

.pagetextcontent {
  margin: 2vw 5vw;
}

.pagetextcontentcurriculum {
  margin: 0vw 0vw 0vw 5vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.pagetextcontent a {
  color: #243952;
}

.pagetextcontentworkareas {
  width: 35vw;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.catalogletters {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 36px;
  line-height: 36px;
  text-align: center;
  background: #243952;
  color: white;
  font-family: "Playfair Display SC", serif;
  font-size: 20px;
  flex-shrink: 0;
}

.catalogtitles {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  justify-content: center;
}

.catalogtitles h3 {
  font-size: 20px;
}

.catalogtitles a {
  text-decoration: none;
  color: black;
}

.catalogtitles a:visited {
  color: black;
}

.course {
  background: rgba(93, 145, 205, 0.1);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
}

.course:hover {
  cursor: pointer;
}

.teachingoverlay {
  position: absolute;
  top: 200px;
  right: 2vw;
  /* height: 0; */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.caretdown {
  margin-left: auto;
  margin-right: 20px;
  font-size: 20px;
}

.teachingoverlay .catalogtitles {
  width: 550px;
  background: white;
  padding: 6px 12px 6px 12px;
  color: black;
}

.talks {
  margin: 2vw 5vw;
}

.gaveldiv {
  width: 100%;
  text-align: center;
}

.articles {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-bottom: 40px;
}

.articles div {
  max-width: 33%;
}

.articles img {
  max-width: calc(33vw - 80px);
  max-height: 400px;
}

.book {
  max-height: 400px;
}

.teachingcontent {
  width: 100%;
}

.teachinghide {
  display: none;
}

.teachingshow {
  display: block;
}

.contactcardlogo {
  width: 100px;
}

.contacttop {
  margin-bottom: 40px;
}

.googlemapsdiv {
  margin-top: 5px;
}

.workareascontent {
  padding: 30px;
  height: 100%;
  margin-right: 0vw;
}
.workareasimagediv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

#workareastitleexterior {
  display: none;
}

#teachingtitleexterior {
  display: none;
}

.logoforsmallscreen {
  display: none;
}

.contacttopcomplete {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;
}

.la-check {
  color: green;
}

.la-times {
  color: red;
}

.whatsapplogo {
  height: 20px;
  vertical-align: text-bottom;
}

.soundCloud {
  width: 50%;
}

.workareaslist li {
  margin-top: 10px;
}

/* for changes on nav */
@media only screen and (max-width: 1023px) {
  nav {
    display: none;
    min-width: 40vw;
  }
  nav ul {
    flex-direction: column;
    align-items: center;
  }
  nav ul li {
    background: rgba(93, 145, 205, 1);
    width: 40vw;
    border-bottom: white 1px solid;
  }
  .showMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: rgba(93, 145, 205, 1);
    box-shadow: none;
    border: solid 1px white;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    line-height: 34px;
    flex-shrink: 0;
  }

  .showMenu .la-bars {
    font-size: 18px;
    font-weight: bolder;
  }
  header {
    flex-direction: column;
    align-items: center;
    height: 15vh;
  }
  .logo img {
    height: 8vh;
  }
  .landingpagetopworkareas {
    flex-direction: column;
  }
  #workareastitleexterior {
    display: block;
    order: 1;
    margin: 2vw 5vw;
  }
  #workareastitleinterior {
    display: none;
  }
  #worksareastext {
    order: 3;
  }
  .workareaphoto {
    max-width: 100%;
  }
  #workareasimage {
    order: 2;
    width: 100vw;
    height: 28vh;
    object-fit: cover;
  }
  .pagetextcontentcurriculum {
    justify-content: flex-start;
  }
  .curriculum {
    padding: 10px 30px 0px 30px;
    width: 70vw;
  }
  .profilephoto {
    max-width: 23vw;
  }
  .curriculum p {
    font-size: 16px;
  }

  #teachingtitleexterior {
    display: block;
    order: 1;
    margin: 2vw 5vw;
  }
  #teachingtitleinterior {
    display: none;
  }
  #teachingtext {
    order: 3;
  }
  #teachingimage {
    order: 2;
    width: 100vw;
    height: 28vh;
    object-fit: cover;
  }
  .bonecos {
    display: none;
  }
  .catalogue {
    width: 100%;
  }
  .contactpage {
    flex-direction: column;
  }
  .pagetextcontent h1,
  .pagetextcontentworkareas h1,
  .pagetextcontentcurriculum h1 {
    font-size: 26px;
  }

  .pagetextcontent p,
  .pagetextcontentworkareas p,
  .contactpage p {
    font-size: 16px;
  }

  .pagetextcontent h2 {
    font-size: 20px;
  }
  .landingpagetopworkareas h1 {
    font-size: 26px;
  }
  .teachingmainsection h2 {
    font-size: 22px;
  }
  .teachingmainsection h3 {
    font-size: 18px;
  }

  .contentcontact {
    margin: auto;
  }

  .contentcontact h1 {
    font-size: 26px;
  }
  .contactpageleft h2,
  .contactpageright h2 {
    font-size: 22px;
  }
  .logoforsmallscreen {
    display: block;
  }
  .logoforlargescreen {
    display: none;
  }
  .contactcardlogo {
    margin-left: 15px;
  }
  .homeimage {
    /* height: 110vh; */
    min-height: 750px;
    max-height: none;
    object-position: left;
  }
  .overlay {
    top: 17vh;
    width: 100vw;
  }

  .overlaytext {
    top: 17vh;
    width: 100vw;
    min-width: 100vw;
    margin: 0;
  }
  .overlaytext > div {
    margin: 15px 30px;
  }
  .homepagemissiondiv {
    min-width: 80%;
    max-width: 80%;
  }

  .soundCloud {
    width: 100%;
  }
}
@media only screen and (max-width: 369px) {
  header {
    padding-left: 0px;
  }
  nav {
    width: 100vw;
  }
  nav ul li {
    background: rgba(93, 145, 205, 1);
    width: 100vw;
  }
  .showMenu {
    width: 29px;
    height: 29px;
    border-radius: 29px;
    line-height: 29px;
  }
  .showMenu .la-bars {
    font-size: 16px;
  }
  .contacttopcomplete {
    flex-direction: column;
    /* align-items: center; */
  }
  .contentcontact {
    width: 95%;
  }
  .contactitem {
    margin-left: 5px;
  }
  .contactitem a {
    font-size: 16px;
  }
  .contacttop {
    margin-bottom: 0px;
  }
  .googlemapsdiv iframe {
    width: 200px;
    height: 200px;
  }
  #contactform {
    width: 95vw;
    margin: auto;
  }
  .caretdown {
    font-size: 16px;
    margin-right: 0px;
  }
  .teachingmainsection h3 {
    font-size: 16px;
  }
  .catalogletters {
    font-size: 18px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 32px;
  }
  .course {
    padding-left: 10px;
    padding-right: 10px;
  }

  ul li {
    font-size: 16px;
  }
  ol li {
    font-size: 16px;
  }
  .landingpagetopcurriculum {
    flex-direction: column;
  }
  .pagetextcontentcurriculum h1 {
    margin: 2vw 5vw;
    font-size: 26px;
  }
  .pagetextcontentcurriculum {
    background: rgba(93, 145, 205, 1);
    color: white;
    margin: 0;
  }
  .profilephoto {
    align-self: center;
    max-width: 50vw;
  }
  .curriculum {
    width: 85vw;
    margin: auto;
    padding: 12px;
  }
  .curriculum h2,
  .talks h2 {
    font-size: 20px;
  }
  .talks h3 {
    font-size: 18px;
  }
  .book {
    max-width: 80vw;
    max-height: none;
  }
  .articles {
    flex-direction: column;
  }
  .articles div {
    max-width: 100%;
  }
  .articles img {
    max-width: 80vw;
  }
  video {
    width: 90vw;
    margin: auto;
  }
  .talks iframe {
    width: 90vw;
    height: 60vw;
  }
  footer p {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .overlay {
    top: 17vh;
    width: 100vw;
  }
  .overlaytext {
    top: 17vh;
    width: 100vw;
    margin: 0;
    height: 100%;
    min-width: 100vw;
    /* padding: 10px; */
  }

  .overlaytext > div {
    margin-top: 10px;
    margin: 10px 0 0 0;
    padding: 10px 15px;
  }

  .overlaytext h1 {
    font-size: 22px;
  }
  .overlaytext h3 {
    font-size: 16px;
  }
  .homepagemissiondiv {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    min-width: 100%;
  }
  .homepagemission {
    width: 140px;
    height: 140px;
  }
  .homepagemission p {
    font-size: 16px;
  }
  .homecontact {
    width: 125px;
    height: 40px;
  }
  .homecontact a {
    font-size: 16px;
  }
  .homeimage {
    min-height: 1000px;
    max-height: none;
    object-position: left;
  }

  .soundCloud {
    width: 50%;
  }
}

/* 'sm': '640px', */
@media only screen and (min-width: 370px) and (max-width: 639px) {
  header {
    padding-left: 0px;
  }
  nav {
    width: 100vw;
  }
  nav ul li {
    background: rgba(93, 145, 205, 1);
    width: 100vw;
  }
  .showMenu {
    width: 29px;
    height: 29px;
    border-radius: 29px;
    line-height: 29px;
  }
  .showMenu .la-bars {
    font-size: 16px;
  }
  .contacttopcomplete {
    flex-direction: column;
    /* align-items: center; */
  }
  .contentcontact {
    width: 95%;
  }
  .contactitem {
    margin-left: 5px;
  }
  .contactitem a {
    font-size: 16px;
  }
  .contacttop {
    margin-bottom: 0px;
  }
  .googlemapsdiv iframe {
    width: 300px;
    height: 300px;
  }
  #contactform {
    width: 95vw;
    margin: auto;
  }
  .caretdown {
    font-size: 16px;
    margin-right: 0px;
  }
  .teachingmainsection h3 {
    font-size: 16px;
  }
  .catalogletters {
    font-size: 18px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 32px;
  }
  .course {
    padding-left: 10px;
    padding-right: 10px;
  }

  ul li {
    font-size: 16px;
  }
  ol li {
    font-size: 16px;
  }
  .landingpagetopcurriculum {
    flex-direction: column;
  }
  .pagetextcontentcurriculum h1 {
    margin: 2vw 5vw;
    font-size: 26px;
  }
  .pagetextcontentcurriculum {
    background: rgba(93, 145, 205, 1);
    color: white;
    margin: 0;
  }
  .profilephoto {
    align-self: center;
    max-width: 50vw;
  }
  .curriculum {
    width: 85vw;
    margin: auto;
    padding: 12px;
  }
  .curriculum h2,
  .talks h2 {
    font-size: 20px;
  }
  .talks h3 {
    font-size: 18px;
  }
  .articles {
    flex-direction: column;
  }
  .articles div {
    max-width: 100%;
  }
  .articles img {
    max-width: 80vw;
  }
  video {
    width: 90vw;
    margin: auto;
  }
  .talks iframe {
    width: 90vw;
    height: 60vw;
  }
  .homeimage {
    /* height: 110vh; */
    min-height: 750px;
    max-height: none;
    object-position: left;
  }
  footer p {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .overlay {
    top: 17vh;
    width: 100vw;
  }
  .overlaytext {
    top: 17vh;
    width: 100vw;
    margin: 0;
    height: 100%;
    min-width: 100vw;
    /* padding: 10px; */
  }

  .overlaytext > div {
    margin-top: 10px;
    margin: 10px 0 0 0;
    padding: 10px 15px;
  }

  .overlaytext h1 {
    font-size: 22px;
  }
  .overlaytext h3 {
    font-size: 16px;
  }
  .homepagemissiondiv {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    min-width: 100%;
  }
  .homepagemission {
    width: 140px;
    height: 140px;
  }
  .homepagemission p {
    font-size: 16px;
  }
  .homecontact {
    width: 125px;
    height: 40px;
  }
  .homecontact a {
    font-size: 16px;
  }
  .book {
    max-width: 80vw;
    max-height: none;
  }

  .soundCloud {
    width: 100%;
  }
}

/* 'md': '768px', */
@media only screen and (max-width: 768px) {
}

/* 'lg': '1024px', */
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  .overlaytext h1 {
    font-size: 22px;
  }
  .overlaytext h3 {
    font-size: 16px;
  }
  .homepagemission {
    width: 140px;
    height: 140px;
  }
  .homepagemission p {
    font-size: 16px;
  }
  footer p {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .homecontact {
    width: 125px;
    height: 40px;
  }
  .homecontact a {
    font-size: 16px;
  }

  .pagetextcontent h1,
  .pagetextcontentworkareas h1,
  .pagetextcontentcurriculum h1 {
    font-size: 26px;
  }

  .pagetextcontent p,
  .pagetextcontentworkareas p {
    font-size: 16px;
  }

  .pagetextcontent h2 {
    font-size: 20px;
  }

  .teachingmainsection {
    width: 90%;
  }
  #catalogo {
    font-size: 20px;
  }

  .catalogtitles h3 {
    font-size: 18px;
  }

  .caretdown {
    font-size: 14px;
  }

  .catalogletters {
    font-size: 16px;
    border-radius: 28px;
    width: 28px;
    height: 28px;
    line-height: 28px;
  }

  .pagetextcontentworkareas {
    width: 50vw;
  }

  .workareaphoto {
    width: 50vw;
  }
  .workareascontent li {
    font-size: 16px;
  }
  .curriculum {
    padding: 10px 30px 0px 30px;
    width: 70vw;
  }
  .profilephoto {
    max-width: 23vw;
  }
  .curriculum p {
    font-size: 16px;
  }
  .pagetextcontentcurriculum {
    justify-content: flex-start;
  }

  .contentcontact {
    width: 95%;
  }

  .contentcontact h1 {
    font-size: 26px;
  }

  .contentcontact h2 {
    font-size: 20px;
  }
  .contactpage {
    gap: 40px;
  }

  .contactpage p {
    font-size: 16px;
  }
  .contactpageleft {
    width: 40%;
  }
  .contactpageright {
    width: 40%;
  }
  #contactform {
    width: 100%;
  }

  .contactcardlogo {
    width: 75px;
    margin-left: 20px;
  }
  .googlemapsdiv iframe {
    width: 400px;
    height: 400px;
  }
}

/* 'xl': '1280px', */
@media only screen and (min-width: 1280px) and (max-width: 1535px) {
  .overlaytext {
    top: 4vh;
  }
  .overlaytext h1 {
    font-size: 28px;
  }
  .overlaytext h3 {
    font-size: 16px;
  }
  .homepagemission {
    width: 140px;
    height: 140px;
  }
  .homepagemission p {
    font-size: 16px;
  }
  .homecontact {
    width: 125px;
    height: 40px;
  }
  .homecontact a {
    font-size: 16px;
  }
  .pagetextcontent h1,
  .pagetextcontentworkareas h1,
  .pagetextcontentcurriculum h1 {
    font-size: 26px;
  }

  .pagetextcontent p,
  .pagetextcontentworkareas p {
    font-size: 16px;
  }

  .pagetextcontent h2 {
    font-size: 20px;
  }
  .teachingmainsection {
    width: 90%;
  }
  #catalogo {
    font-size: 20px;
  }

  .catalogtitles h3 {
    font-size: 18px;
  }

  .caretdown {
    font-size: 14px;
  }

  .catalogletters {
    font-size: 16px;
    border-radius: 28px;
    width: 28px;
    height: 28px;
    line-height: 28px;
  }

  .pagetextcontentworkareas {
    width: 50vw;
  }

  .workareaphoto {
    width: 65vw;
  }
  .workareascontent li {
    font-size: 16px;
  }
  .curriculum {
    padding: 10px 30px 0px 30px;
    width: 70vw;
  }
  .profilephoto {
    max-width: 23vw;
  }
  .curriculum p {
    font-size: 16px;
  }

  .contentcontact {
    width: 95%;
  }

  .contentcontact h1 {
    font-size: 26px;
  }

  .contentcontact h2 {
    font-size: 20px;
  }
  .contactpage {
    gap: 40px;
  }

  .contactpage p {
    font-size: 16px;
  }
  .contactpageleft {
    width: 40%;
  }
  .contactpageright {
    width: 40%;
  }
  #contactform {
    width: 100%;
  }

  .contactcardlogo {
    width: 75px;
    margin-left: 20px;
  }
  .googlemapsdiv iframe {
    width: 500px;
    height: 500px;
  }
}

/* '2xl': '1600px'*/
@media only screen and (min-width: 1536px) and (max-width: 1919px) {
  .overlaytext {
    top: 5vh;
  }
  .overlaytext h1 {
    font-size: 28px;
  }
  .overlaytext h3 {
    font-size: 16px;
  }
  .homepagemission {
    width: 155px;
    height: 155px;
  }
  .homepagemission p {
    font-size: 17px;
  }

  .curriculum {
    padding: 10px 30px 0px 30px;
  }
  .curriculum p {
    font-size: 16px;
  }
}
